@import '../../../styles/all';

.toggle-details {
  border-width: 0;
  color: $link-color;
  font-size: .8em;
  letter-spacing: .05em;
  line-height: 2rem;
  cursor: pointer;
  text-align: right;
  text-transform: uppercase;
  width: 100%;

  @include breakpoint($phablet-landscape) {
    display: none;
  }
}

.project-detail {
  margin: 0 auto;
  position: relative;
  width: 100%;

  @include breakpoint($phablet-landscape) {
    align-items: center;
    display: flex;
    flex-direction: column;
    top: $masthead-height-mobile;
    @include breakpoint-landscape() {
      flex-direction: row;
      top: 0;
    }
  }

  &__category {
    @extend %title-card__category;
  }

  &__name {
    @extend %title-card__title;
    padding-bottom: .5rem;
  }

  &__title {
    @extend %title-card;
    z-index: 3;

    &--open {
      opacity: 1;
    }
    @include breakpoint($phablet-landscape) {
      // left: 0;
      max-width: 100%;
      position: static;
      order: 2;
      @include breakpoint-landscape() {
        flex: 1 0 auto;
        max-width: 47%;
        order: 1;
      }

    }
  }

  &__info {
    height: auto;
    max-height: 0;
    overflow: hidden;


    &--open {
      max-height: 50vh;
      display: flex;
      flex-direction: column;
      // overflow: auto;
    }

    @include breakpoint($phablet-landscape) {
      // overflow: auto;
      max-height: 40vh;
    }
  }

  &__stats {
    padding: 0.5em 0 1.5em;

    h3 {
      font-size: 0.9rem;
    }
  }

  &__key {
    display: inline-block;
    font-size: 0.9em;
    font-weight: normal;
    min-width: 27%;
    text-transform: uppercase;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.6;
    overflow: auto;
    padding: 1em 1em 1em 0;
  }

  @include breakpoint($phablet-landscape) {
    margin: 0 auto;
    width: 95%;
  }

  .full-size {
    transition: width .4s ease-out .2s;
    width: 100%;

    @include breakpoint($phablet-landscape) {
      @include breakpoint-landscape() {
        flex: 1 0 auto;
        width: 47%;
      }
    }
  }

  .project-item {
    .image {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &::before {
        content: '';
        display: block;
        // padding-bottom: calc(100vh - #{$masthead-height-desktop + $footer-height-desktop});
        padding-bottom: 100vh;
        width: 100%;

        @include breakpoint($phablet-landscape) {
          padding-bottom: 35vh;
          @include breakpoint-landscape() {
            // padding-bottom: calc(100vh - #{$masthead-height-mobile});
            padding-bottom: 100vh;
          }
        }
      }
    }
  }
}

