@import '../../styles/all';

%arrow-shadow {
  border: 2px solid rgba($black, 0.3);
  border-right: 0;
  border-bottom: 0;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transition: opacity 0.3s ease-out;
  transform: translateY(-50%) rotate(135deg);
  z-index: 3;
}

%slide-arrow {
  &:before {
    @extend %arrow-shadow;
    border-color: $logotype-green;
    content: '';
    display: block;
    top: 0px;
    transform: none;
    right: 1px;
  }
}

%arrow-wrapper {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  width: 5rem;
  z-index: 1;

  @include breakpoint ($tablet) {
    display: none;
  }


  &:before {
    background-image: linear-gradient(to left, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0) 95%);
    content: '';
    display: block;
    height: 100%;
    opacity: 0;
    width: 100%;
    transition: opacity 0.3s ease-out;
  }

  &:hover {
    > * {
      opacity: 1;
    }
    &:before {
      opacity: 0.8;
    }
  }
}

.next {
  &-arrow__wrapper {
    @extend %arrow-wrapper;
    right: 0;
  }
  &-arrow {
    @extend %arrow-shadow;
    @extend %slide-arrow;
    right: 15px;
  }
}

.prev {
  &-arrow__wrapper {
    @extend %arrow-wrapper;
    left: 0;
    &:before {
      background-image: linear-gradient(to right, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0) 95%);
    }
  }
  &-arrow {
    @extend %arrow-shadow;
    @extend %slide-arrow;
    left: 30px;
    transform: translateY(-50%) rotate(-45deg);
  }
}