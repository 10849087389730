@import '../../styles/all';

.inner-content--stories {
  align-items: center;
  display: flex;
  height: 100%;

  @include breakpoint($phablet-landscape) {
    align-items: flex-start;
  }

  @include breakpoint($phone) {
    flex-direction: column;
    align-items: stretch;
  }
}

.subnav {
  border: 0;
  box-sizing: border-box;
  flex: 1 0 auto;
  line-height: 2;
  text-align: right;
  // width: 25%;
  @include breakpoint($phone) {

    // border-right: 1px solid rgba($body-fg, 0.2);
  }

  a {
    display: block;
    font-size: 1.1em;
    line-height: 2;
    overflow: hidden;
    padding: 0 10%;
    text-overflow: ellipsis;

    @include breakpoint($phablet-landscape) {
      padding: 0 20px;
    }

    &.active {
      color: $logotype-green;
    }
  }
}


@include breakpoint($phablet-landscape) {

}

