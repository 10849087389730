@mixin reset-blocks($global-line-height) {
  border: 0;
  line-height: $global-line-height;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

@mixin font-gen($family-name, $file-name) {
  @font-face {
    font-family: $family-name;
    src: url('#{$file-name}.eot');
    src: url('#{$file-name}.eot?#iefix') format('embedded-opentype'),
         url('#{$file-name}.woff') format('woff'),
         url('#{$file-name}.ttf') format('truetype'),
         url('#{$file-name}.svg#svgFontName') format('svg');
  }
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

@mixin prefix-value($property, $value) {
  #{$property}: -webkit-#{$value};
  #{$property}: -moz-#{$value};
  #{$property}: -ms-#{$value};
  #{$property}: -o-#{$value};
  #{$property}: #{$value};
}

@mixin breakpoint($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin breakpoint-height($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}

@mixin breakpoint-landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin breakpoint-portrait() {
  @media (orientation: portrait) {
    @content;
  }
}
// END _mixins.scss >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
