
%pseudo-block {
  content: '';
  display: block;

}

%clearer {
  &:after {
    @extend %pseudo-block;
    clear: both;
    height: 0;
  }
}

%title-card {
  backdrop-filter: blur(2px);
  background-color: rgba($black, 0.45);
  bottom: 3rem;
  font-size: 0.8em;
  left: 3rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  max-width: 33%;
  opacity: 0.3;
  position: absolute;
  text-shadow: 0 0 4px rgba($black, 0.8);
  transition: opacity .6s ease-in-out;
  padding: 1rem;

  &:hover {
    color: $body-fg;
    opacity: 1;
  }

  @media (hover: none) {
    opacity: 1;
    color: $body-fg;
  }

  @include breakpoint ($tablet) {
    max-width: 60%;
  }
}

%title-card__title {
  font-size: 1.8rem;
  padding-right: 1rem;

  @include breakpoint ($med-phone-landscape) {
    font-size: 1.2rem;
  }
}

%title-card__category {
  border-bottom: 1px solid rgba($body-fg, 0.2);
  // font-family: 'Poppins', sans-serif;
  // font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0.2rem;
  padding-bottom: 0.2rem;
  text-transform: uppercase;
}

%absolute-base {
  left: 0;
  position: absolute;
  top: 0;
}

%pos-vertical-center {
  top: 50%;
  @include prefix(transform, translateY(-50%));
}

%pos-horizontal-center {
  left: 50%;
  @include prefix(transform, translateX(-50%));
}

%pos-center-middle {
  left: 50%;
  top: 50%;
  @include prefix(transform, translateX(-50%) translateY(-50%));
}
