@import '../../../styles/all';

.inner-content.project {
  .project-item {
    float: left;
    width: 100%;

    // @include breakpoint ($desktop-landscape) {
    //   width: 25%;
    // }

    // @include breakpoint ($tablet) {
    //   width: 33%;
    // }

    // @include breakpoint ($med-phone-landscape) {
    //   margin-bottom: 15px;
    //   width: 50%;
    // }

    // @include breakpoint ($med-phone) {
    //   width: 100%;
    // }

    &__title {
      @extend %title-card;
      // display: inline-block;
      // flex: 1;
      // backdrop-filter: blur(2px);
      // background-color: rgba($black, 0.3);
      // bottom: 3rem;
      // font-size: 0.8em;
      // left: 3rem;
      // letter-spacing: 0.05em;
      // line-height: 1.5;
      // max-width: 33%;
      // opacity: 0.3;
      // position: absolute;
      // text-shadow: 0 0 4px rgba($black, 0.8);
      // transition: opacity .6s ease-in-out;
      // padding: 1rem;

      // @media (hover: none) {
      //   opacity: 1;
      //   color: $body-fg;
      // }

      // @include breakpoint ($tablet) {
      //   max-width: 60%;
      // }

    }

    &__name,
    &__category {
      display: block;
    }

    &__category {
      @extend %title-card__category;
    }

    &__name {
      @extend %title-card__title;
    }

    a {
      // align-items: center;
      // background-color: $nav-active-bg;
      box-sizing: border-box;
      color: $nav-active-fg;
      display: block;
      height: 100%;
      // margin-bottom: 7px;
      // margin-right: 15px;
      overflow: hidden;
      position: relative;
      // padding: 2px;
      transition: color .6s ease-in-out;


      .image {
        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        // background-size: 101% auto;
        background-size: cover;
        display: block;
        width: 100vw;
        // flex: 1;
        position: relative;
        transition: background-size .6s ease-out .4s;

        &:before {
          content: '';
          display: block;
          //padding-bottom: calc(100vh - #{$masthead-height-desktop + $footer-height-desktop});
          padding-bottom: 100vh;

          @include breakpoint ($tablet) {
            // padding-bottom: calc(100vh - #{$masthead-height-mobile});
            padding-bottom: 100vh;
          }
        }
      }

      &:hover {
        color: $body-fg;
        .project-item__title {
          opacity: 1;
        }
      }
    }
  }
}