@import 'mixins';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
abbr,
del,
dfn,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  @include reset-blocks(1.2em);
}

ol,
ul {
  list-style: none;
}

a:link,
a:visited {
  text-decoration: none;
}

button {
  @include reset-blocks(0);
  appearance: none;
  border: 0;
  background: none;

}

// END _reset.scss >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
