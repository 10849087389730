@import '../../styles/all';


// LISTING VIEW |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

.categories {
  position: relative;
  width: calc(100% - 112px);
  z-index: 2;

  @include breakpoint ($tablet-landscape) {
    width: calc(100% - 56px);
  }
}

.category {
  box-sizing: border-box;
  width: 100%;


  @include breakpoint ($tablet-landscape) {
    flex-direction: column;
    align-items: stretch;
  }

  @include breakpoint (680px) {
    height: auto;
  }

  .projects {
    @include breakpoint ($tablet-landscape) {
      width: 100%;
    }
  }

}

.inner-content.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  position: relative;
  width: 100%;
}

.projects {

  @include breakpoint (680px) {
    flex-wrap: wrap;
  }


  // slick carousel ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  .slick-dots {
    bottom: 2rem;
    height: 0.5rem;
    left: 50%;
    min-width: 190px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    z-index: 3;


    &.disabled {
      opacity: 0;
    }

    li {
      display: inline-block;
      margin-right: 3px;

      button {
        background-color: transparent;
        border: 1px solid $nav-active-fg;
        height: 0.5rem;
        overflow: hidden;
        padding: 0;
        text-indent: -200em;
        width: 0.5rem;
      }

      &.slick-active {

        button {
          background-color: $nav-active-fg;
        }
      }


    }
  }


}

