@import 'variables';

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400&family=Open+Sans:wght@600&display=swap');

html, body {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: $body-bg;
  color: $body-fg;
  font-family: $body-font;
  font-size: 14px;
  font-weight: 400;
  // overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  font-weight: 600;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-hover-color;
  }
}

.clear-after {
  @extend %clearer;
}

// WEBKIT SCROLLBARS

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: $black-trans;
}

::-webkit-scrollbar-track-piece {
  background-color: $black-trans;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($body-fg, 0.4);
}

::-webkit-scrollbar-corner {
  display: none;
}
