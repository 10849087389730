// COLORS

$body-fg: #fff;
$link-color: #efefef;
$link-hover-color: #dedede;
$nav-border: #6f6f6f;
$nav-bg: #494949;
$nav-active-bg: #1d1d1d;
$nav-active-border: #282828;
$nav-active-fg: #aaa;
$info-key: #999;
$overlay-tint: rgba(0, 0, 0, .7);
$black: rgba(0, 0, 0, 1);
$black-trans: rgba(0, 0, 0, 0);
$black-semi-trans: rgba(0, 0, 0, .4);
$logo-bg-grey: rgba(150, 150, 150, .6);
$logotype-green: #a2cf6f;

$body-bg: #000;

// FONT NAMES ||||||

$body-font: 'Nanum Gothic', 'Open Sans', Arial, Helvetica, sans-serif;
$title-font: 'Open Sans', Arial, Helvetica, sans-serif;

// BREAKPOINTS
$desktop-landscape: 1280px;
$tablet: 769px;
$tablet-landscape: 1024px;
$phone: 414px;
$med-phone: 500px;
$phone-landscape: 480px;
$phablet-landscape: 896px;
$med-phone-landscape: 680px;

// DIMENSIONS:
$masthead-height-desktop: 87px;//67px;
$masthead-top-padding-desktop: 4px;
$masthead-bottom-padding-desktop: 18px;
$masthead-total-height-desktop: $masthead-height-desktop + $masthead-top-padding-desktop + $masthead-bottom-padding-desktop;

$masthead-height-mobile: 70px;
$masthead-top-padding-mobile: 0;// 6px;
$masthead-bottom-padding-mobile: 0; //12px;
$masthead-total-height-mobile: $masthead-height-mobile + $masthead-top-padding-mobile + $masthead-bottom-padding-mobile;

$footer-height-desktop: 40px;
