@import '../all.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

main.wrapper {
  min-height: 100vh;
  // overflow-x: hidden;
  // overflow-y: scroll;
  position: relative;

  &:after {
    background-image: linear-gradient(to bottom, $black-trans 20%, $black 100%);
    bottom: 0;
    height: 55px;
    top: auto;

    @include breakpoint ($tablet) {
      display: none;
    }
  }

  @include breakpoint($tablet) {
    overflow-y: auto;
  }
}

footer.wrapper {
  height: $footer-height-desktop;
  // overflow: hidden;
  position: relative;
  text-align: right;
  z-index: 2;

  @include breakpoint($tablet-landscape) {
    bottom: 0;
    position: fixed;
    width: 100%;
  }

  &::before {
    background-image: linear-gradient(to top, rgba(0,0,0,0.45)0%, rgba(0,0,0,0)100%);
    content: '';
    display: block;
    height: 4vh;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 0;

    @include breakpoint($phablet-landscape) {
      background-image: linear-gradient(to top, rgba(0,0,0,0.90)60%, rgba(0,0,0,0)100%);
      height: 100%;
    }
  }

  small {
    display: inline-block;
    line-height: 3em;
    position: relative;
    padding: 0 5%;
    z-index: 1;
  }

  hr {
    margin: 0 auto;
    display: none;
  }

  @include breakpoint($tablet) {
    @include prefix(transform, none);
    display: none;
  }
}

section.inner-wrapper {
  // height: calc(100vh - #{$footer-height-desktop} - #{$masthead-height-desktop});
  height: calc(100vh - #{$footer-height-desktop});
  position: relative;
  width: 100%;
  z-index: 1;

  @include breakpoint($tablet-landscape) {
    height: auto;
    // min-height: calc(100vh - #{$footer-height-desktop} - #{$masthead-height-desktop});
    min-height: calc(100vh - #{$footer-height-desktop});
  }

  @include breakpoint($tablet) {
  // height: calc(100vh - #{$masthead-height-mobile});


    @include breakpoint-height($phone) {

    }
  }
}

.home section.inner-wrapper {


  @include breakpoint($tablet) {

  }
}

hr {
  border: 0;
  border-bottom: 1px solid $body-fg;
  margin: 5px auto;
  position: relative;
  width: 99%;
  z-index: 2;
}

// .animated-wrapper {
//   height: 100vh;
//   left: 50%;
//   position: absolute;
//   top: calc(50vh - #{$masthead-height-desktop});
//   transform: translate(-50%, -50%);
//   transition: opacity .5s ease-out;
//   width: 100%;

//   &.initial {

//     @include breakpoint($tablet) {
//     }
//   }
//   // }

//   &.ng-leave,
//   &.ng-enter.ng-enter-active {
//     opacity: 1;
//   }

//   &.ng-enter,
//   &.ng-leave.ng-leave-active {
//     opacity: 0;
//     position: absolute;
//     width: 100%;
//   }
// }
