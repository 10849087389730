@import '../../../styles/all';

.text-container {
  flex: 2 1 auto;
  position: relative;
  width: 75%;

  &:before {
    // border-right: 1px solid rgba($body-fg, 0.2);
    content: '';
    height: 100%;
    left: -1px;
    position: absolute;
    top: 0;

    @include breakpoint($phablet-landscape) {
      border: 0;
    }
  }

  h2 {
    border-bottom: 1px solid rgba($body-fg, 0.2);
    font-size: 1.8rem;
    margin: 0 1rem;
    padding: 5px 0;

    @include breakpoint($phablet-landscape) {

    }
  }

  .content {
    background-color: $overlay-tint;
    box-sizing: border-box;
    margin: 20px;
    max-height: 60vh;
    min-height: 50vh;
    overflow: auto;
    padding: 32px 30% 32px 40px;
    letter-spacing: 0.05em;
    line-height: 1.5em;

    p {
      line-height: 1.4em;
      margin-bottom: .9em;
    }

    li {
      line-height: 1.4;
    }

    ul {
      list-style: square;
      margin: 1em;
    }
  }
}


@include breakpoint($phablet-landscape) {
  .text-container .content {
    padding: 1rem 1.5rem 2rem 1rem;

    @include breakpoint-portrait() {
      max-height: none;
      overflow: visible;
      padding: 0;
    }
  }
}

