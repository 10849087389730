@import '../../styles/all';

%expando {
  height: 100%;
  width: 100%;
}

// %home-arrows {
//   &:after {
//     @include prefix(transform, rotate(45deg));
//     border: 2px solid rgba(255,255,255,0.7);
//     border-top-width: 0;
//     border-right-width: 0;
//     bottom: 38%;
//     content: '';
//     display: block;
//     height: 1.5vw;
//     position: absolute;
//     left: 10%;
//     width: 1.5vw;

//     @include breakpoint($tablet) {
//       bottom: calc(50% - 2.5vw);
//       height: 5vw;
//       left: 3vw;
//       width: 5vw;
//     }
//   }
// }

.logo-block {
  @extend %expando;
  background-color: $overlay-tint;
  font-size: 0;
  height: auto;
  left: 0;
  max-width: 560px;
  pointer-events: none;
  position: absolute;
  text-indent: -200em;
  top: 50%;
  transform: translateY(-90%);
  width: 50%;
  z-index: 8;

  span {
    background-color: $logo-bg-grey;
    display: block;
    height: 140%;
    left: 8.8%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) skew(-15deg);
    width: 5.2%;

    &.blank {
      left: auto;
      right: 94.1%;
      width: 100%;
    }

    &.r {
      left: 15.9%;
    }

    &.a {
      left: 23%;
    }

    &.n {
      left: 30.3%;
    }

    &.k {
      left: 37.4%;
    }
  }

  &:after {
    background-image: url('../../assets/logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80% auto;
    bottom: auto;
    content: '';
    display: block;
    padding: 0 10% 29%;
    position: relative;
    z-index: 2;
  }

  @include breakpoint($tablet-landscape){
    display: none;
  }
}

.animated-wrapper .home {
  position: absolute;
  @extend %pos-center-middle;
  height: 100%;

  @include breakpoint($tablet){
    height: 100%;
  }

  .slick-list {
    height: 100%;

     .slick-track {
        height: 100%;

        .slick-slide > div {
          @extend %expando;
          height: 100%;
        }
     }
  }

  .slick {
    @include prefix(transition, opacity 0.9s ease-out);
    opacity: 0;

    &.slick-initialized {
      opacity: 1;
    }

  }
  // .prev-arrow,
  // .next-arrow {
  //   @extend %home-arrows;
  //   height: 100%;
  //   left: 0;
  //   position: absolute;
  //   text-indent: -200em;
  //   top: 0;
  //   width: 20%;
  //   z-index: 2;

  //   @include breakpoint($tablet) {
  //     width: 0;
  //   }
  // }

  .next-arrow {
    left: auto;
    right: 0;

    &:after {
      border-width: 0;
      border-top-width: 2px;
      border-right-width: 2px;
      right: 10%;
      left: auto;

      @include breakpoint($tablet) {
        right: 3vw;
      }
    }
  }
}

.slideshow,
.home,
.owl-stage-outer,
.owl-stage,
.owl-item {
  @extend %expando;
}

.owl-nav.disabled,
.owl-dots.disabled {
  display: none;
}

.owl-stage-outer {
  overflow: hidden;
}

.owl-stage {
  @include prefix(transition-duration, .8s !important);
  @include prefix(transition-timing-function, ease-in-out);
}

.owl-item {
  float: left;
}

.item {
  height: 100%;
  min-height: 400px;
  min-width: 320px;
  position: relative;
  // top: 50%;
  // @include prefix(transform, translateY(-50%));
  width: 100%;

  // @include breakpoint($tablet) {
  //   min-height: 200px;
  //   max-height: 100%;
  // }

  &:before {
    content: '';
    display: block;
    // padding-bottom: calc(100vh - #{$masthead-height-desktop + $footer-height-desktop});
    padding-bottom: 100vh;
    width: 100%;

    @include breakpoint ($tablet) {
      // padding-bottom: calc(100vh - #{$masthead-height-mobile});
      padding-bottom: 100vh;
    }
  }

  .image {
    @extend %expando;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: absolute;
    top: 0;

    @include breakpoint-height($phone) {
      height:100%;
    }

    .caption {
      display: none;
    }
  }

}

