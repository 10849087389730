@import '../../styles/all';

.masthead {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-height: $masthead-height-desktop;
  padding-top: $masthead-top-padding-desktop;
  position: absolute;
  width: 100%;
  z-index: 2;

  &::before {
    backdrop-filter: blur(1px);
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.95)0%, rgba(0,0,0,0.01)100%);
    content: '';
    display: block;
    height: 87px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  .nav-wrapper {
    align-items: flex-start;
    // background-color: rgba($black, 0.6);
    box-sizing: border-box;
    display: flex;
    flex: 1;
    margin: 0 auto;
    position: relative;
    padding: 0 5%;
    width: 100%;
    z-index: 3;

    * {
      box-sizing: border-box;
    }

    @include breakpoint-height($phone) {
      padding-bottom: 7px;
    }
    .mobile-trigger {
      background: none;
      border: 0;
      display: none;
      height: 2rem;
      position: relative;
      top: 1.2rem;
      width: 2rem;

      @include breakpoint($tablet) {
        display: block;
      }

      &:after,
      &:before {
        background-color: rgba($black, .1);
        border: 2px solid $nav-border;
        border-left: 0;
        border-right: 0;
        content: '';
        display: block;
        height: 20px;
        pointer-events: initial;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
      }

      &:before {
        border-bottom: 0;
        height: 1px;
        top: 11px;
      }
    }
  }

  hr {
    flex: 1 0 auto;
    padding-top: 0.5em;
    display: none;
  }

  h1 {
    flex: 1 0 auto;
    font-size: .1em;
    margin-top: 0.8rem;
    max-height: $masthead-height-desktop;
    width: 25%;

    @include breakpoint($tablet) {
      // bottom: 0;
      max-height: $masthead-height-desktop;
      right: 0;
      width: 280px;
    }

    @include breakpoint($phone-landscape) {
      // bottom: auto;
      // height: 45px;
      // top: 10px;
    }

    a.logo {

      display: block;
      position: relative;
      z-index: 3;
      background-image: url('../../assets/logo.svg');
      background-position: top left;
      background-repeat: no-repeat;
      // background-size: 100% auto;
      background-size: contain;
      max-width: 285px;

      &:after {
        @extend %pseudo-block;
        height: auto;
        left: -16px;
        padding-bottom: 18%;
        // position: absolute;
        width: 80%;

        @include breakpoint($tablet) {
          // background-size: auto 90%;
          height: 100%;
          left: 0;
          padding-bottom: 12%;
        }
      }
    }
  }

  @include breakpoint($tablet) {
    padding: $masthead-top-padding-mobile 0 $masthead-bottom-padding-mobile;
    max-height: $masthead-height-mobile;
    min-height: $masthead-height-mobile;
  }
}


