@import '../../styles/all';

%nav-child {
  // border: 1px solid $nav-border;
  // border-left: 0;
  flex: 1 1 auto;

  @include breakpoint($tablet) {
    flex: 0;
  }
}
%nav-link {
  // background-color: $nav-bg;
  color: $link-color;
  // flex: 1;
  // font-size: .8em;
  font-size: 1em;
  min-height: 2.2em;
  letter-spacing: 2px;
  line-height: 1.9;
  text-align: center;

  &:hover {
    // color: $nav-active-fg;
  }

  &.active {
    // background-color: $nav-active-bg;
    // color: $nav-active-fg;
    color: $logotype-green;
  }

  @include breakpoint($tablet) {
    align-items: center;
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: flex-start;
    line-height: 30px;
    min-height: 3rem;
    padding: 3px 1rem;
    width: 100%;
  }
}

.primary-nav {
  // border-left: 1px solid $nav-border;
  // display: inline-block;
  align-items: flex-start;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  margin-top: 1.3rem;
  width: 55%;

  @include breakpoint($tablet) {
    border-left: 0;
    flex-direction: column;
    margin-top: 0;
    min-height: 350px;
    overflow: hidden;
    padding-top: $masthead-height-mobile;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0.3em;
    transition: all .8s ease-in-out;
    width: 50vw;
    z-index: 10;

    > * {
      opacity: 0;
      position: relative;
      right: -55vw;
      transition-property: opacity, right;
      transition-duration: 0.8s;
      transition-timing-function: ease-in-out;
      width: 100%;
    }

    @include breakpoint-height($phone) {
      top: 20px;
    }

    &.nav-open {
      > * {
        opacity: 1;
        pointer-events: initial;
        right: 0vw;
      }
    }

  }

  .sub-nav{
    &__wrapper {
      @extend %nav-child;
      position: relative;

      @include breakpoint($tablet) {
        display: flex;
        flex-direction: column;
      }
    }
    &__trigger {
      @extend %nav-link;
      width: 100%;
      height: 100%;
    }
    &__link {
      @extend %nav-child;
      @extend %nav-link;
      line-height: 1.4;
      padding: 0.5em;


      @include breakpoint($tablet) {
        justify-content: flex-start;
        padding-left: 2rem;
        text-align: left;
      }
    }
    &__links {
      // border-left: 1px solid $nav-border;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-bottom: 0.5em;
      padding-top:1.5rem;
      width: 100%;

      @include breakpoint($tablet) {
        background-color: transparent;
        backdrop-filter: none;
        padding-bottom: 0;
        padding-top: 0;
        position: static;
      }
    }
  }

  &__link {
    @extend %nav-child;
    @extend %nav-link;

  }

}